import styled from "styled-components";

export const ArticleNavWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    display: grid;
    grid-template-columns: ${({ theme }) => theme.widthSize.HOME_NAV_WIDTH}px 1fr;
  }

  ${({ theme }) => theme.preventUserSelect};
`;
