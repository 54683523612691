import React from "react";
import { ArticleInfoWrapper } from "./wrapper";
import { ArticleDate } from "./date";
import { ArticleDescription } from "./description";
import { ArticleTitle } from "./title";

export const ArticleInfoSection = ({ title, description, date }) => {
  return (
    <ArticleInfoWrapper>
      <ArticleTitle>{title}</ArticleTitle>
      <ArticleDescription>{description}</ArticleDescription>
      <ArticleDate>{date}</ArticleDate>
    </ArticleInfoWrapper>
  );
};
