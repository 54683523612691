import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { ArticleContainer } from "./container";
import { BlogLink } from "./link";
import { ImageStyles } from "../image/styles";
import { ImageWrapper } from "../image/wrapper";
import { ArticleInfoSection } from "./info";

export const ArticleSection = ({ node }) => {
  const {
    slug,
    frontmatter: { hero_image, hero_image_alt, title, description, date },
  } = node;
  return (
    <ArticleContainer title={title}>
      <BlogLink to={`/${slug}`}>
        <ImageWrapper>
          <GatsbyImage
            image={getImage(hero_image)}
            alt={hero_image_alt}
            imgStyle={ImageStyles}
          />
        </ImageWrapper>
        <ArticleInfoSection
          title={title}
          description={description}
          date={date}
        />
      </BlogLink>
    </ArticleContainer>
  );
};
