import styled from "styled-components";
import { H4 } from "../../../../common";

export const CountBox = styled(H4).attrs({ as: "span" })`
  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.regular};

  padding: 5px 10px;
  min-width: 35px;

  text-align: center;

  background-color: ${({ theme }) => theme.colors.grayBoxTranslucent};
  border-radius: 5px;
`;
