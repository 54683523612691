import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { CaptionBox } from "./caption";
import { CategoryNavigator } from "./category-nav";
import { TagNavigator } from "./tag-nav";
import { NavigatorWrapper } from "./wrapper";

export const NavigatorSection = () => {
  const {
    allMdx: { nodes: articles },
  } = useStaticQuery(CategoryTagDataQuery);
  return (
    <NavigatorWrapper>
      <CaptionBox>카테고리</CaptionBox>
      <CategoryNavigator articles={articles} />
      <CaptionBox>주요 태그</CaptionBox>
      <TagNavigator articles={articles} />
    </NavigatorWrapper>
  );
};

const CategoryTagDataQuery = graphql`
  query CategoryTagData {
    allMdx {
      nodes {
        frontmatter {
          category
          tags
        }
      }
    }
  }
`;
