import styled from "styled-components";

export const FilterWrapper = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 50px 20px;
  height: 210px;

  ${({ theme }) => theme.preventUserSelect};
`;
