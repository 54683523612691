import React, { useEffect, useState } from "react";
import { getTagFullInfoDictionary } from "../../../utils";
import { TargetLinkBox } from "./common";
import { NavWrapper } from "./common/wrapper";

export const TagNavigator = ({ articles }) => {
  const [loading, setLoading] = useState(true);
  const [tagInfos, setTagInfos] = useState([]);

  useEffect(() => {
    setTagInfos(
      getTagFullInfoDictionary(articles.map((node) => node.frontmatter.tags))
    );
    setLoading(false);
  }, [articles]);

  if (loading) return <></>;

  return (
    <NavWrapper>
      {tagInfos.filter((tag) => !tag.ignore).slice(0, 10).map((tag, idx) => (
        <TargetLinkBox
          name={tag.ko}
          count={tag.count}
          pathTo={`/tag/${tag.slug}`}
          key={idx}
        />
      ))}
    </NavWrapper>
  );
};
