import React from "react";
import { FilterContainer } from "./container";
import { FilterCondition } from "./condition";
import { TotalCount } from "./count";
import { FilterWrapper } from "./wrapper";

export const FilterInfoContainer = ({ condition, totalCount }) => {
  return (
    <FilterWrapper>
      <FilterContainer>
        <FilterCondition>{condition}</FilterCondition>
        <TotalCount>총 {totalCount}개의 게시글</TotalCount>
      </FilterContainer>
    </FilterWrapper>
  );
};
