import styled from "styled-components";

export const ArticleContainer = styled.article`
  ${({ theme }) => theme.baseStyles(["transform"])};
  width: 100%;
  
  &:hover {
    transform: scale(1.01);
  }
`;
