import React from "react";
import { ArticleWrapper } from "./wrapper";
import { PaginationSection } from "./pagination";
import { ArticleSection } from "./article";

export const ArticlesSection = ({ articlesInfoProps }) => {
  const { nodes: articles, pageInfo } = articlesInfoProps;
  return (
    <ArticleWrapper>
      {articles.map((node) => (
        <ArticleSection key={node.id} node={node} />
      ))}
      <PaginationSection pageInfo={pageInfo} />
    </ArticleWrapper>
  );
};
