import React from "react";
import { CurrentPageWrapper } from "./wrapper";
import { CurrentPageText } from "./text";

export const CurrentPage = ({ idx }) => {
  return (
    <CurrentPageWrapper key={idx}>
      <CurrentPageText>{idx + 1}</CurrentPageText>
    </CurrentPageWrapper>
  );
};
