import React from "react";
import { graphql } from "gatsby";
import { TagFilterInfoSection } from "../components/intro/filter/tag";
import { ArticleNavSection } from "../components/article_nav";
import SeoHelmet from "../components/seo";
import { getNamesByTagSlug } from "../utils";
import Layout from "../components/layout";

const SearchByTag = ({ data, pageContext }) => {
  const searchResultInfo = {
    filterCondition: pageContext?.ko || getNamesByTagSlug(pageContext.slug).ko,
    totalCount: data.allMdx.pageInfo.totalCount,
  };

  const articlesInfoProps = data.allMdx;

  const { skip, slug } = pageContext;
  const pathname =
    skip === 0 ? `/tag/${slug}` : `/tag/${slug}/page/${skip / 10 + 1}`;

  return (
    <>
      <SeoHelmet
        title={searchResultInfo.filterCondition}
        description={`All ${searchResultInfo.totalCount} articles on ${
          pageContext?.en || getNamesByTagSlug(slug).en
        } tag.`}
        pathname={pathname}
      />
      <Layout>
        <TagFilterInfoSection searchResultInfo={searchResultInfo} />
        <ArticleNavSection articlesInfoProps={articlesInfoProps} />
      </Layout>
    </>
  );
};

export const query = graphql`
  query ($slug: [String!]!, $skip: Int!) {
    allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      filter: { frontmatter: { tags: { in: $slug } } }
      limit: 10
      skip: $skip
    ) {
      nodes {
        frontmatter {
          title
          description
          date(formatString: "MMMM D, YYYY")
          category
          tags
          hero_image {
            childImageSharp {
              gatsbyImageData(width: 900, aspectRatio: 2)
            }
          }
          hero_image_alt
        }
        id
        slug
      }
      pageInfo {
        currentPage
        hasNextPage
        hasPreviousPage
        pageCount
        totalCount
      }
    }
  }
`;

export default SearchByTag;
