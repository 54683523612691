import styled from "styled-components";
import { Paragraph } from "../../../../common";

export const ArticleDescription = styled(Paragraph)`
  margin: 0px 0px 10px 0px;
  padding: 0px;
  line-height: ${({ theme }) => theme.lineHeight.big};

  font-size: ${({ theme }) => theme.fontSize.sm};
  font-weight: ${({ theme }) => theme.fontWeight.medium};
`;
