import styled from "styled-components";

export const CountBoxWrapper = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;

  height: 100%;
  padding: 0 5px;

  display: flex;
  justify-content: center;
  align-items: center;
`;
