import { Link } from "gatsby";
import styled from "styled-components";

export const BlogLink = styled(Link)`
  display: flex;
  flex-direction: column;

  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.grayBorder};
  border-radius: 15px;

  @media ${({ theme }) => theme.device.desktop} {
    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: minmax(180px, 1fr);
  }
`;
