import { Link } from "gatsby";
import styled from "styled-components";

export const TagetLink = styled(Link)`
  position: relative;
  padding: 10px 0 10px 10px;
  border-radius: 10px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayTranslucent};
  }
`;
