import React from "react";
import { PageLinkWrapper } from "./wrapper";
import { PageLinkText } from "./text";

export const PageLink = ({ to, innerText }) => {
  return (
    <PageLinkWrapper to={to}>
      <PageLinkText>{innerText}</PageLinkText>
    </PageLinkWrapper>
  );
};
