import styled from "styled-components";

export const ImageWrapper = styled.div`
  padding: 10px;

  @media ${({ theme }) => theme.device.desktop} {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    img {
      height: 150px;
      width: 150px;
    }
  }
`;
