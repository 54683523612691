import React from "react";
import { CountInfo } from "./count";
import { TagetLink } from "./link";
import { Name } from "./name";

export const TargetLinkBox = ({ name, count, pathTo }) => {
  return (
    <TagetLink to={pathTo}>
      <Name>{name}</Name>
      <CountInfo count={count} />
    </TagetLink>
  );
};
