import React from "react";
import { ArticlesSection } from "./articles";
import { NavigatorSection } from "./navigator";
import { ArticleNavWrapper } from "./wrapper";

export const ArticleNavSection = ({ articlesInfoProps }) => (
  <ArticleNavWrapper>
    <NavigatorSection />
    <ArticlesSection articlesInfoProps={articlesInfoProps} />
  </ArticleNavWrapper>
);
