import React, { useEffect, useState } from "react";
import { getCategoryInfoWithCount } from "../../../utils";
import { TargetLinkBox } from "./common";
import { NavWrapper } from "./common/wrapper";

export const CategoryNavigator = ({ articles }) => {
  const [loading, setLoading] = useState(true);
  const [categoryInfos, setCategoryInfos] = useState([]);

  useEffect(() => {
    setCategoryInfos(
      getCategoryInfoWithCount(
        articles.map((node) => node.frontmatter.category)
      )
    );
    setLoading(false);
  }, [articles]);

  if (loading) return <></>;

  return (
    <NavWrapper>
      {categoryInfos.map((category, idx) => (
        <TargetLinkBox
          name={category.ko}
          count={category.count}
          pathTo={category.slug ? `/category/${category.slug}` : "/"}
          key={idx}
        />
      ))}
    </NavWrapper>
  );
};
