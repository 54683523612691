import styled from "styled-components";

export const CurrentPageWrapper = styled.span`
  border-radius: 50%;

  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: ${({ theme }) => theme.colors.highlightColor};
`;
