import styled from "styled-components";

export const PaginationWrapper = styled.nav`
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 10px 0 50px 0;

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    margin-bottom: 0;
  }
`;
