import { Link } from "gatsby";
import styled from "styled-components";

export const PageLinkWrapper = styled(Link)`
  border-radius: 50%;

  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.grayBorder};
  }
`;
