import styled from "styled-components";

export const ArticleWrapper = styled.section`
  margin: 0px 20px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  & > article {
    margin-bottom: 15px;
  }

  @media ${({ theme }) => theme.device.maxScreenWidth} {
    max-width: ${({
      theme: {
        widthSize: { MAX_SCREEN_WIDTH, HOME_NAV_WIDTH },
      },
    }) => `${MAX_SCREEN_WIDTH - HOME_NAV_WIDTH}px`};
  }
`;
