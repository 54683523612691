import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import {
  getTargetPaginationPath,
  getRootPathFromPagination,
} from "../../../../utils";
import { ChevronLeft, ChevronRight } from "../../../common/icon";
import { PaginationContainer } from "./container";
import { CurrentPage } from "./current";
import { PageLink } from "./link";
import { PaginationWrapper } from "./wrapper";

// 한번에 이동 가능한 페이지 개수. 한 페이지에서 보여주는 게시글 개수 등이 수정되면 깨질 수 있음.
const VISIBLE_PAGE_COUNT = 5; 

const getNearByPagesInfo = (currentPage, pageCount) => {
  const pages = [currentPage];
  let i = 0
  while (i < VISIBLE_PAGE_COUNT) {
    i++;
    if (currentPage - i >= 1) {
      pages.push(currentPage - i);
    }
    if (currentPage + i <= pageCount) {
      pages.push(currentPage + i);
    }
  }
  pages.sort((a, b) => a - b);
  return pages;
}

export const PaginationSection = ({ pageInfo }) => {
  const { currentPage, pageCount } = pageInfo; // const { currentPage, hasNextPage, pageCount, hasPreviousPage } = pageInfo;
  const { pathname } = useLocation();

  const [rootPath, setRootPath] = useState(pathname);

  useEffect(() => {
    if (!pathname.endsWith(currentPage)) return;
    setRootPath(getRootPathFromPagination(pathname, currentPage));
  }, [pathname, currentPage]);

  const nearByPages = getNearByPagesInfo(currentPage, pageCount)
  const hasFirstPage = nearByPages.includes(1);
  const hasLastPage = nearByPages.includes(pageCount);
  return (
    <PaginationWrapper>
      <PaginationContainer>
        {!hasFirstPage && (
          <PageLink
            to={getTargetPaginationPath(rootPath, 1)}
            innerText={<ChevronLeft size={24} />}
          />
        )}

        {nearByPages?.map((page, _) =>
            currentPage === page ? (
              <CurrentPage key={page-1} idx={page-1} />
            ) : (
              <PageLink
                to={getTargetPaginationPath(rootPath, page)}
                key={page-1}
                innerText={page}
              />
            )
          )}

        {!hasLastPage && (
          <PageLink
            to={getTargetPaginationPath(rootPath, pageCount)}
            innerText={<ChevronRight size={24} />}
          />
        )}
      </PaginationContainer>
    </PaginationWrapper>
  );
};
